<template>
  <div class="kjDetails">
    <!-- <img class="body-text-img" src="../../assets/home/315@2x.png" alt /> -->
    <div class="top">
      <img class="kj-img" src="../assets/detail/kj.png" alt="" />
      <div class="kj-name">
        <span class="left" style="font-weight: bolder;">
          {{ routerParams.name }}
        </span>
        <span class="right" style="font-weight: bolder;">
          {{ routerParams.price }}
        </span>
      </div>
      <img src="../assets/detail/yjbh.png" alt="" class="kj-img2" />
      <div class="kj-nh" style="font-weight: bolder;">
        Power Consumption per Unit Hashrate:
        {{ routerParams.minbuyCalculation }}
      </div>
      <div class="kj-reason">
        <div>
          <img class="zan" src="../assets/detail/zan.png" alt="" />
          <!-- <img class="tjly" src="../assets/detail/tjly.png" alt="" /> -->
          Recommended Reasons
        </div>
        <div style="margin-top:15px;">
          {{ routerParams.locate }}
        </div>
      </div>
    </div>
    <img style="margin: 10px 0" src="../assets/detail/rxhd.png" alt="" />
    <van-collapse
      v-model="activeNames"
      style="font-weight: bolder;color: #333333;"
    >
      <van-collapse-item title="Product Details" name="1">
        <div class="body-bg">
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿机型号</span>
            <span class="body-text-right"
              >{{ routerParams.typeName }}{{ routerParams.batchNum }}</span
            >
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">Mining Machine Output</span>
            <span class="body-text-right">BTC</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿机型号</span>
            <span class="body-text-right"
              >{{ routerParams.typeName }}{{ routerParams.batchNum }}</span
            >
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">Mining Machine Hashrate</span>
            <span class="body-text-right">{{
              routerParams.calculationPower
            }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Mining Machine Power Consumption</span>
            <span class="body-text-right">{{ routerParams.powerCost }}</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿场位置</span>
            <span class="body-text-right">{{ routerParams.locate }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">每日电费</span>
            <span class="body-text-right">{{
              routerParams.electricityCost
            }}</span>
          </div>
          <div class="body-text-line"></div>
          <div class="body-text-body">
            <span class="body-text-left">矿机价格</span>
            <span class="body-text-right">{{ routerParams.price }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">质保费用</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">Estimated Daily Output</span>
            <span class="body-text-right">{{ routerParams.locatePrice }}</span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">预估单日收益</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div> -->
          <div class="body-text-body">
            <span class="body-text-left">Reference Shutdown Coin Price</span>
            <span class="body-text-right">{{ routerParams.preElectric }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Electricity Fee</span>
            <span class="body-text-right">{{
              routerParams.electricityCost
            }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Platform Management Fee</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Hosting Service Fee Share</span>
            <span class="body-text-right">20% of daily output</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Payout to Bitroo Wallet</span>
            <span class="body-text-right"
              >Production starts 15 days after payment</span
            >
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Payout to Mining Pool</span>
            <span class="body-text-right"
              >Production starts 45 days after payment</span
            >
          </div>
        </div></van-collapse-item
      >
      <van-collapse-item
        class="content_item"
        title="Deduction Explanation"
        name="2"
      >
        <div class="quest_content">
          1、When placing an order, you need to pay for the mining machine,
          logistics clearance, and deployment fees. The amount shown on the
          payment page is final.
        </div>
        <div class="quest_content">
          2、At the first activation of the mining machine, the platform
          management fee and electricity fee for the month will be deducted. The
          calcul- ation methods are as follows:
          <br />
          ①Platform Management Fee (Paid Monthly, D- ays Due D): Fee = Monthly
          Fee * Conversion Fa- ctor * Units
          <br />
          ②Electricity Fee (For Remaining Days D): Fee = Electricity Unit Price
          * Machine Power / 1000 * 24 * D * (1 + Line Loss Rate) * Units (Line
          Loss- Rate ≈ 5%)
        </div>
        <div class="quest_content">
          3、After the first deduction, the system will de- duct the electricity
          and management fees for the following month from your point card ac-
          count on the 26th of each month at 1:00 (UTC+8). If activation occurs
          after the 26th, fees for the- current and next month will be charged
          toge- ther.
        </div>
        4、If insufficient balance leads to payment failure for electricity or
        management fees, the mining machine will be shut down, and you w- ill
        lose mining earnings.
      </van-collapse-item>
      <van-collapse-item
        class="content_item"
        title="Purchase and Hosting Process"
        name="3"
      >
        <div class="quest_content">
          1. Complete the payment process according to the instructions on the
          DataRun website (Note: Currently, only TRC20-USDT payments are
          accepted).
        </div>
        <div class="quest_content">
          2. After placing the order, the machines will be deployed within T+7
          calendar days for spot purchases or T+45 calendar days for futures
          orders. Production will begin the next day after deployment.
        </div>
        3. During the deployment period, please pre-deposit the corresponding
        amount under “Electricity Fees” to avoid disruptions in machine
        production and unnecessary losses.
      </van-collapse-item>
      <van-collapse-item
        class="content_item"
        title="Earnings Explanation"
        name="4"
      >
        <div class="quest_content">
          1. Daily earnings from mining machines are affected by the total
          network hashrate and fluctuate based on market difficulty and changes
          in on-chain hashrate. You may refer to the BitFuFu Pool theoretical
          earnings calculator.
        </div>
        2. Current Static Earnings = Hashrate * Theoretical Daily Earnings Per
        T.
      </van-collapse-item>
      <van-collapse-item title="Frequently Asked Questions" name="5">
        <div class="quest_title">
          How are hosting fees deducted?
        </div>
        <div class="quest_content">
          Hosting fees are automatically deducted from the daily earnings. The
          backend displays the actual credited earnings, which are net of
          hosting fees.
        </div>
        <div class="quest_title">
          When are daily earnings and electricity fees deducted?
        </div>
        <div class="quest_content">
          Daily earnings and electricity fees are deducted at 18:00 PM (GMT+8)
          each day.
        </div>
        <div class="quest_title">
          What about withdrawal times and fees?
        </div>
        <div class="quest_content">
          1. Withdrawals are processed on a T+0 basis. Withdrawals made before
          17:00 (GMT+8) on working days will be credited the same day, while
          withdrawals made after 17:00 will be credited the next working day.
          Earnings are distributed as usual during holidays.
          <br />
          2. The minimum withdrawal amount is 0.00054 BTC per transaction, with
          a fee of 0.00011 BTC per transaction (fees may be adjusted based on
          BTC network congestion to ensure withdrawal efficiency).
        </div>
        <div class="quest_title">
          What happens if the electricity fee balance is insufficient?
        </div>
        <div class="quest_content">
          If the pre-deposited electricity fee is insufficient to cover two days
          of required fees, the mining machine will stop operating and
          producing. Once the pre-deposited amount is sufficient to cover at
          least two days of electricity fees, the machine will resume operations
          by 17:00 PM (GMT+8) the following day. Users are responsible for
          monitoring their electricity fee balance to avoid disruptions in
          production and unnecessary losses.
        </div>
        <div class="quest_title">
          Can users transfer or withdraw their mining machines?
        </div>
        <div class="quest_content">
          1. Users who purchase and host mining machines through the platform
          can transfer their machines to other registered users on the platform
          if they no longer wish to continue the cooperation. The transfer price
          will be negotiated between the buyer and seller, and the platform’s
          customer service will assist with the transfer process. Please consult
          your sales representative for details.
          <br />
          2. Users can withdraw their mining machines. The platform supports
          using partner logistics for transportation, with related costs borne
          by the user. Please consult your sales representative for more
          details.
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="kj-sm">
      <div class="footer-title">Disclaimer</div>
      <div class="footer-body">
        1.Per the agreement, purchases are final, and no returns or exchanges
        are allowed once the purc- hase is complete.
        <br />
        2.The platform is not responsible for losses ca- used by the following
        uncontrollable risks: unfo- reseen, unavoidable, or insurmountable
        events, including natural disasters such as floods, volc- anic
        eruptions, earthquakes, landslides, fires, government actions or
        directives, city-scale po wer grid accidents, or societal anomalies such
        as wars, strikes, or unrest.
        <br />
        3.Static earnings calculations are based on the c- urrent hashrate
        difficulty and do not account for mining pool fees. These calculations
        are for refe- rence only, and the platform does not guarantee BTC
        output. DataRun provides real hosting services, but due to network
        fluctuations, machine perfor- mance, and other factors, there may be
        variability in operations. The platform does not guarantee 100% stable
        operation.
        <!-- 1、按照约定，一旦您购买完成，将不支持更换或退款。
        2、不对以下不可控风险所造成的损失负责:不能预见、
        不能避免或不能克服的客观事件，包括自然灾害如洪水、
        火山爆发、地震、山崩、火灾、被政府部门评定为百年
        不遇级别以上的风暴和恶劣气候等，政府行为和政府指
        令，城市级别的电网供电事故，以及社会异常事件如战 争、罢工、动乱等。
        3、24K保留对本套餐的最终解释权。 -->
      </div>
    </div>
    <!-- <div class="footer-body" v-html="routerParams.productExplain"></div> -->
  </div>
</template>

<script>
export default {
  name: "kjDetails",
  data() {
    return {
      activeNames: ["1"],
      num: 0,
      routerParams: {},
      id: "",
    }
  },
  created() {},
  methods: {
    getHomeList() {
      this.$request
        .post("/kjInfo/getKjInfo", { id: this.id })
        .then(
          (res) => {
            if (res.code == 200) {
              this.routerParams = res.data
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {
    this.id = this.$route.query.id
    this.getHomeList()
    // this.routerParams = JSON.parse(this.$route.params.itemObj);
    // console.log(this.routerParams);
  },
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kjDetails {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  overflow: auto;
  padding: 14px 20px 20px;
  text-align: left;
  border-radius: 12px;
  padding: 20px 15px;
  .top {
    // height: 480px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 20px;
    .kj-img {
      height: 160px;
      margin-top: 20px;
    }
    .kj-name {
      width: 100%;
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      line-height: 25px;
      .left {
        font-size: 18px;
        color: #4a4a4a;
      }
      .right {
        font-size: 18px;
        color: #f8b34d;
      }
    }
    .kj-img2 {
      margin-top: 8px;
      width: 100%;
    }
    .kj-nh {
      width: 100%;
      margin-top: 16px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      text-align: left;
    }
    .kj-reason {
      margin-top: 20px;
      width: 100%;
      height: 124px;
      background: rgba(248, 222, 77, 0.12);
      border-radius: 8px;
      padding: 16px 7px 0 12px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: left;
      .zan {
        width: 20px;
        margin-right: 8px;
      }
      .tjly {
        width: 68px;
      }
    }
  }
  .body-bg {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    padding: 20px 16px;
    background: #fff;
    border-radius: 12px;
    .body-text-img {
      margin-bottom: 20px;
    }
    .body-text-line {
      height: 1px;
      background: #f3f3f3;
      margin-bottom: 20px;
    }
    .body-text-body {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      line-height: 18px;
      .body-text-left {
        color: #93a5b2;
      }
      .body-text-right {
        text-align: right;
        color: #4a4a4a;
      }
    }
    .body-text-body:last-child {
      margin-bottom: 0px;
    }
  }
  .content_item {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
  .quest_title {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }
  .quest_content {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
  }
  .kj-sm {
    width: 100%;
    margin-top: 10px;
    padding: 16px 16px 0;
    background: #ffffff;
    // height: 250px;
    border-radius: 8px;
    color: #333333;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;

    .footer-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #ff3500;
    }
    .footer-body {
      width: 100%;
      font-size: 13px;
      line-height: 25px;
      letter-spacing: 1px;
      font-size: 13px;
    }
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
<style lang="scss">
.van-cell__title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}
.van-tabs__nav {
  background: #f8f8f8;
}
.van-tabs__line {
  background-color: #1677ff;
}
.van-collapse-item__content {
  font-weight: normal;
  color: #333333;
}
</style>
